@charset "UTF-8";

@font-face {
  font-family: "apolo";
  src:url("fonts/apolo.eot");
  src:url("fonts/apolo.eot?#iefix") format("embedded-opentype"),
    url("fonts/apolo.woff") format("woff"),
    url("fonts/apolo.ttf") format("truetype"),
    url("fonts/apolo.svg#apolo") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "apolo" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "apolo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar-check:before {
  content: "\64";
}
.icon-certificate:before {
  content: "\66";
}
.icon-chevron-up:before {
  content: "\67";
}
.icon-drag-indicator:before {
  content: "\69";
}
.icon-edit:before {
  content: "\6a";
}
.icon-express:before {
  content: "\6b";
}
.icon-fork:before {
  content: "\6c";
}
.icon-history:before {
  content: "\6d";
}
.icon-logout:before {
  content: "\6f";
}
.icon-map:before {
  content: "\70";
}
.icon-paper:before {
  content: "\72";
}
.icon-paragraph:before {
  content: "\73";
}
.icon-planning:before {
  content: "\74";
}
.icon-recover:before {
  content: "\75";
}
.icon-refresh:before {
  content: "\76";
}
.icon-text:before {
  content: "\77";
}
.icon-trash:before {
  content: "\78";
}
.icon-user:before {
  content: "\79";
}
.icon-report-download:before {
  content: "\65";
}
.icon-more-vertical:before {
  content: "\61";
}
.icon-copy:before {
  content: "\43";
}
.icon-phone:before {
  content: "\44";
}
.icon-cellphone:before {
  content: "\45";
}
.icon-menu:before {
  content: "\46";
}
.icon-camera:before {
  content: "\68";
}
.icon-dashboard:before {
  content: "\71";
}
.icon-save:before {
  content: "\49";
}
.icon-profile:before {
  content: "\48";
}
.icon-user-group:before {
  content: "\4a";
}
.icon-deleted:before {
  content: "\42";
}
.icon-calendar:before {
  content: "\63";
}
.icon-wallet:before {
  content: "\7a";
}
.icon-visibility-off:before {
  content: "\41";
}
.icon-visibility:before {
  content: "\47";
}
.icon-search:before {
  content: "\4b";
}
.icon-vector:before {
  content: "\4c";
}
.icon-home:before {
  content: "\4e";
}
.icon-location:before {
  content: "\4f";
}
.icon-world:before {
  content: "\50";
}
.icon-add:before {
  content: "\62";
}
.icon-descount:before {
  content: "\51";
}
.icon-check:before {
  content: "\52";
}
.icon-arrow-2:before {
  content: "\54";
}
.icon-convocatoria:before {
  content: "\55";
}
.icon-information:before {
  content: "\6e";
}
.icon-plus:before {
  content: "\56";
}
.icon-arrow2:before {
  content: "\53";
}
.icon-align-center:before {
  content: "\57";
}
.icon-align-left-1:before {
  content: "\58";
}
.icon-align-right:before {
  content: "\59";
}
.icon-routing:before {
  content: "\5a";
}
.icon-exclamation:before {
  content: "\30";
}
.icon-visa:before {
  content: "\31";
}
.icon-amex:before {
  content: "\32";
}
.icon-master-card:before {
  content: "\33";
}
.icon-card:before {
  content: "\34";
}
.icon-send-email:before {
  content: "\35";
}
.icon-body:before {
  content: "\36";
}
.icon-graph:before {
  content: "\38";
}
.icon-tshirt:before {
  content: "\39";
}
.icon-inscription:before {
  content: "\21";
}
.icon-inscription-change:before {
  content: "\22";
}
.icon-vector-2:before {
  content: "\37";
}
.icon-vector-1:before {
  content: "\4d";
}
.icon-arrow-3:before {
  content: "\23";
}
.icon-swap-vertical:before {
  content: "\24";
}
.icon-settings:before {
  content: "\25";
}
.icon-paypal:before {
  content: "\26";
}
.icon-store:before {
  content: "\27";
}
.icon-wallet2:before {
  content: "\28";
}
.icon-coupon:before {
  content: "\29";
}
.icon-close:before {
  content: "\2a";
}
.icon-filter:before {
  content: "\2b";
}
.icon-individual:before {
  content: "\2c";
}
.icon-result:before {
  content: "\2d";
}
.icon-time:before {
  content: "\2e";
}
.icon-font-size:before {
  content: "\2f";
}
.icon-header:before {
  content: "\3a";
}
.icon-group-36535:before {
  content: "\3b";
}
.icon-dolar:before {
  content: "\3c";
}
.icon-shirt:before {
  content: "\3d";
}
.icon-horn:before {
  content: "\3e";
}
.icon-chevron-up-1:before {
  content: "\3f";
}
.icon-chevron-left:before {
  content: "\40";
}
.icon-chevron-down:before {
  content: "\5b";
}
.icon-contact-book:before {
  content: "\5d";
}
.icon-input-box:before {
  content: "\5e";
}
.icon-auto-1:before {
  content: "\5f";
}
.icon-video:before {
  content: "\60";
}
.icon-sears:before {
  content: "\7b";
}
.icon-download:before {
  content: "\7c";
}
