/* ====== INSTANT SEARCH ====== */

.ais-SearchBox {
  padding: 24px;
  background-color: #303642;
}

.ais-SearchBox-form {
  display: flex;
  justify-content: center;
}

.ais-SearchBox-input {
  width: 100%;
  padding: 8px;
  border: none;
  max-width: 385px;
  border-radius: 4px 0px 0px 4px;
}

.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-submit {
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 0px 4px 4px 0px;
}

.ais-SearchBox-submit svg {
  width: 14px;
  height: 14px;
}

/* ====== HITS ====== */

.ais-Hits-list {
  list-style: none;
  padding: 0px;
}

/* ====== PAGINATION ====== */

.ais-Pagination {
  margin-top: 32px;
}

.ais-Pagination-list {
  width: 100%;
  gap: 12px;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  list-style: none;
}

.ais-Pagination-item {
  cursor: pointer;
}

.ais-Pagination-item--selected a {
  color: white;
  border: 1px solid #ff6a00;
  background-color: #ff6a00;
}

.ais-Pagination-link {
  padding: 4px 14px;
  border: 1px solid #cecece;
  border-radius: 4px;
}

.ais-Pagination-item--firstPage span,
.ais-Pagination-item--firstPage a,
.ais-Pagination-item--lastPage span,
.ais-Pagination-item--lastPage a,
.ais-Pagination-item--nextPage span,
.ais-Pagination-item--nextPage a,
.ais-Pagination-item--previousPage span,
.ais-Pagination-item--previousPage a {
  border: none;
  color: rgba(0, 0, 0, 0.26);
}
